<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                PARTIALS
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text>
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="bom.items || []"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            style="background-color: #eeeeee"
                        >
                            <v-col cols="12" class="d-flex">
                                <h2 class="my-n3">ITEMS</h2>
                            </v-col>
                        </v-row>
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">{{ item.code }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{ item.remaining - (item.requests || 0) }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.request`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.request"
                                hide-details
                                type="number"
                                :rules="[validateQuantity(item)]"
                            />
                        </div>
                    </template>
                    <template v-slot:[`item.deliverDate`]="{ item }">
                        <div class="d-flex justify-center mb-0">
                            <v-menu
                                ref="menu"
                                v-model="item.menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ attrs, on }">
                                    <v-text-field
                                        v-model="item.deliverDate"
                                        placeholder="Deliver Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="item.deliverDate"
                                    @input="item.menu = false"
                                    :min="min"
                                ></v-date-picker>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:[`item.notes`]="{ item }">
                        <v-textarea
                            v-model="item.notes"
                            hide-details
                            rows="2"
                            placeholder="Notes"
                            class="mb-4"
                        />
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="!validateItems"
                @click="sendPartials"
            >
                SEND
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'SendPartials',
    props: {
        bom: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REQUEST',
                value: 'request',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'DELIVER DATE',
                value: 'deliverDate',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
        ],
        rules: {
            number: v => v > 0 || 'The value is not valid',
            required: v => !!v || 'The value is required',
        },
        deliverDateMenu: false,
        min: new Date().toISOString(),
    }),
    mounted() {
        try {
            for (const item of this.bom.items) {
                item.menu = false
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    computed: {
        validateItems() {
            let response = false
            for (const item of this.bom.items) {
                if (Number(item.request) > 0) {
                    response = true
                    break
                }
            }
            return response
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async sendPartials() {
            try {
                this.loading = true
                let requests = []
                for (let item of this.bom.items) {
                    let request = Number(item.request || 0)
                    if (request > 0) {
                        requests.push({
                            id: item.id,
                            quantity: Number(item.request),
                            remaining: Number(item.remaining),
                            deliverDate: item.deliverDate,
                            notes: item.notes,
                        })
                    }
                }
                const partials = await API.sendPartials({
                    workOrderId: this.bom.workOrderId,
                    bomId: this.bom.id,
                    notificationId: 'NNmtFqZZciXX33CtA5zF',
                    requests,
                })

                this.$emit('replaceItems', partials)
                this.$emit('partialsAlert')
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        validateQuantity(item) {
            return (
                !item.request ||
                (item.request > 0 &&
                    item.remaining - (item.requests || 0) >= item.request) ||
                'This value cannot be assigned'
            )
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
